@import '../../styles/dimensions';
@import '../../styles/breakpoints';

.ctaSection {
    text-align: center;

    @include screen-max-width('lg') {
        margin: 0;
    }
}

.heading {
    margin: auto;
    max-width: 1000px;
}

.heading div h2 {
    @include screen-max-width('lg') {
        font-size: 58px;
    }
}

.subtitle {
    margin: auto;
    max-width: 600px;
}
